body {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
}


.OV50_CHBQS_V2_7_1B {
  @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap');

  @import 'custom';


  .accordion{
    .faq__accordion-item{
      border: 2px solid #2396a4;
    }
    .faq__accordion{
      @media (min-width: 1200px) {
        min-height: 100vh;
      }
    }
  }

  .error_msg {
    color: red;
    background-color: rgb(248, 205, 205);
    padding: 2px 5px;
    border-radius: 5px;
    margin-top: 5px;
  }

  .action-btn,
  .form-check,
  .form-check-input {
    cursor: pointer;
  }

  .form-check {
    // align-items: center;
    text-align: start;
  }

  header {
    background: #eee;
    &.OV50_CHBQS_V2_7_1_header{
      background: white;
      img{
        height: 40px;
      }
    }
  }


  .chat-section {
    width: 100%;
    float: left;

    .chat-wrap {
      max-width: 450px;
      margin: 0 auto;
    }

    .curs_pointer {
      cursor: pointer !important;
    }

    .chat-box-inner {
      position: relative;
      z-index: 5px;
      min-height: 400px;
      padding: 10px;
      overflow: hidden;

      .row-chart-message {
        font-family: Arial, Helvetica, sans-serif;

        .chater-prof {
          display: flex;
          align-items: flex-end;
          max-width: 90%;

          .text-msg {
            background: #e5e7eb;
            padding: 10px 15px;
            margin: 5px 0 0 0;
            display: inline-block;
            border-radius: 5px;
            font-size: 15px;

            &.btn-spc {
              padding: 20px 10px;
              max-width: 335px;
            }
          }

          .call_now {
            background-color: #e5e7eb;
            padding: 18px 15px;
            border-radius: 5px;

            .callbtn {
              background-color: #3b82f6;
              padding: 13px 12px;
              width: 60%;
              margin-left: auto;
              margin-right: auto;
              border-radius: 8px;
              color: #fff;
              font-weight: bold;
              font-size: 18px;

              &:hover {
                background-color: #3877dd;
              }
            }
          }

          .phone {
            font-size: 16px;
            padding: 10px 20px;
          }

          .qs-inner {
            background: #e5e7eb;

            .qs-text {
              text-align: center;
              font-size: 22px;
            }
          }

          .qs-inner1 {
            background: #e5e7eb;

            .qs-text {
              text-align: center;
              font-size: 22px;
            }
          }

          .qs-inner2 {
            background: #e5e7eb;

            .qs-text {
              text-align: center;
              font-size: 22px;
            }
          }

          .mesg19 {
            margin-top: 7px;
          }

          .contact_dt {
            width: 100%;
            margin-top: 7px;

            .action-btn {
              background-color: #3b82f6;
              border-radius: 6px;
              color: #fff;
              font-weight: bold;
              font-size: 19px;
              padding: 15px 37px;
              border: none;

              &:hover {
                background-color: #3172d9;
              }
            }

            .qs-inner {
              form {
                .selectww {
                  color: #706d6d !important;
                  font-size: 14px !important;
                  padding: 10px 10px !important;
                }
              }
            }

            .qs-inner1 {
              form {
                .selectww {
                  color: #706d6d !important;
                  font-size: 14px !important;
                  padding: 10px 10px !important;
                }
              }
            }

            .qs-inner2 {
              form {
                .selectww {
                  color: #706d6d !important;
                  font-size: 14px !important;
                  padding: 10px 10px !important;
                }
              }
            }
          }

          .new_text {
            p {
              font-size: 15px !important;
            }
          }
        }

        .msg-loader {
          img {
            height: 15px;
          }
        }
      }

      .supporter-dp {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: #ddd;
        display: block;
        margin-right: 10px;
        text-align: center;
        border: 1px solid #ddd;
        overflow: hidden;

        img {

          height: 38px;


        }
      }

      .sel-option {
        .action-btn {
          background: #3b82f6;
          padding: 15px 40px;
          // margin-top: 20px;
          // width: 60%;
          color: #fff;
          -webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
          animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
          border-radius: 7px;
          margin-left: auto;
          margin-right: auto;
          font-weight: bold;
          transition: ease-in 300ms;

          &.answ-bnt {
            padding: 15px 20px;
            text-align: left;
          }

          &:hover {
            background: rgb(63, 137, 255);
          }

          &.medibutton{
            max-width: 60%;
          }
        }

        .action-btn-submit {
          background-color: #ff8759;
          ;
          margin-top: 12px;

          &:hover {
            background-color: #ff8759;
            ;
          }
        }

        input[type="radio"]:checked+label {
          background: #1854b4;
        }

        .form-check {
          margin: 10px 0;
          background-color: #fff;
          border: solid 2px #d7dade;
          border-radius: 5px;
          padding-left: 32px;

          .form-check-input {
            background-color: #d0d0d0;
            // border: 0px none !important;
            border: solid 1px #3b82f6 !important;
            height: 25px;
            width: 22px;
            margin-top: 11px;

            &:checked {
              background-color: #1854b4 !important;
            }
          }

          label {
            padding-left: 10px !important;
            padding-top: 3px !important;
            padding-right: 7px;
            background-color: #fff;
            width: 100%;
            height: 50px;
            // border: solid 1px #3b82f6;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            cursor: pointer;
          }

          .label_adj {
            padding-top: 13px !important;
          }
        }

        .selectww {
          color: #b4b9bd !important;
        }

        .form-control {
          border: 1.5px solid #9b9ea0;
          border-radius: 8px;
        }
      }

      .client-replay {
        text-align: right;
        margin-top: 10px;

        .client-replay-vpx {
          display: flex;
          justify-content: end;
          // to fix user dp at the bottom
          align-items: flex-end;

          &.custom-width {
            max-width: 300px;
            margin-left: auto;
          }

          .dp_client_h100 {
            padding-bottom: 10px;

            .dp-client {
              @extend .supporter-dp;
              order: 2;
              margin-right: 0;
              margin-left: 10px;
            }

          }

        }
      }
    }

  }

  .list-items {

    li {
      font-size: 16px;
      position: relative;
      list-style-type: none;
      padding: 4px 0;

      &::before {
        content: "";
        background: url("../../img/OV50_CHBQS_V2_7_1B/index-icon.png") no-repeat center center;
        width: 30px;
        height: 30px;
        display: block;
        position: absolute;
        left: -40px;
        top: 0;
      }
    }
  }

  h1,
  h5 {
    font-family: Arial, Helvetica, sans-serif !important;
  }


  .slide-top {
    -webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  }

  @-webkit-keyframes slide-top {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }

    100% {
      -webkit-transform: translateY(-5px);
      transform: translateY(-5px);
    }
  }

  @keyframes slide-top {
    0% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
    }

    100% {
      -webkit-transform: translateY(-5px);
      transform: translateY(-5px);
    }
  }

  .body-inner {
    // height: 100vh;
  }

  .sec_foot {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    text-align: center;
  }

  footer {
    float: left;
    width: 100%;
    padding-top: 150px;

    p {
      font-size: 13px;
    }

    a {
      color: $colorBlack;
      text-decoration: none;
    }
  }

  .text1 {
    font-family: Georgia, 'Times New Roman', Times, serif;
    font-size: 18px;
    font-weight: bold;
    color: #4b5563;
  }

  .text2 {
    font-size: 32px;
    color: #4b5563;
    padding: 0 5%;
  }

  .text3 {
    padding: 0 5%;
  }

  .support-online {
    font-size: 16px;

    span {
      width: 15px;
      height: 15px;
      border-radius: 50%;
      box-shadow: 0px 0px 1px 1px #0000001a;
      display: inline-block;
      position: relative;
      top: 3px;
      background: #22c55e;
    }


    .pulse {
      animation: pulse-animation 1.5s infinite;
    }

    @keyframes pulse-animation {
      0% {
        box-shadow: 0 0 0 0px rgba(162, 221, 122, 0.616);
      }

      100% {
        box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
      }
    }


  }


  @media screen and (max-width: 760px) {


    .chat-section {
      .chat-box-inner {
        .sel-option {
          .action-btn {
            padding: 15px 30px;
          }
        }
      }
    }

    .text1 {
      font-size: 14px;
    }

    .text2 {
      font-size: 26px;
    }

  }

  @media screen and (max-width: 490px) {
    .client-replay-vpx {
      &.custom-width {
        max-width: 260px !important;
      }
    }

    .chater-prof {
      .text-msg {
        &.btn-spc {
          max-width: 240px !important;
        }
      }
    }

    .text2 {
      font-size: 26px;
    }

    .list-items {
      li {
        padding-left: 35px;
        line-height: 20px;

        &::before {


          left: 0;
          background-size: 20px 20px;
        }
      }
    }

    .chat-section {
      .chat-box-inner {
        .row-chart-message {
          .chater-prof {
            .qs-inner {
              padding: 21px 0px;
            }
          }
        }

        .sel-option {
          .form-check {
            label {
              height: 72px;
            }

            .label_adj1 {
              padding-top: 13px !important;
            }

            .label_adj2 {
              padding-top: 23px !important;
            }

            .form-check-input {
              height: 20px;
              margin-top: 24px;
              width: 19px;
            }
          }
        }

        .sel-option {
          .partbtn {
            padding: 15px 7px;
          }
        }
      }
    }
  }


  // 
  .lightbg {
    background-color:#1B9AAA;
    color: #fff;
    padding: 60px 0;
    h1{
      font-weight: 900;
      line-height: 1.2 !important;
      font-size: 2.5rem;
    }
    h5{
      font-weight: 700;
      line-height: 1.2 !important;
      @media screen and (max-width: 540px) {
        padding: 15px 0;
      }
    }
    p{
      @media screen and (max-width: 540px) {
        margin: 0 0 25px 0;
      }
    }
    .nobulletstick {
      padding: 0;
      list-style: none;
   li {
      padding: 5px 0 5px 25px;
      background: url(../../img/check-mark.png) no-repeat left center;
      
  }
  }
  .btnthird {
    border: 1px solid #922D50;
    background-color: #922D50;
    color: #ffffff;
    padding: 15px 50px;
    font-size: 17px;
    text-transform: uppercase;
    font-weight: 700;
    margin: 15px 0;
    @media screen and (max-width: 540px) {
      margin: 25px 0;
    }
    img{
      height: 20px;
      margin: -2px 0 0 5px;
      position: relative;
    }
  }
}
.companies{
  padding: 20px 0;
img {
  max-height: 70px;
  max-width: 160px;
  margin: 10px;
  @media screen and (max-width: 540px) {
    max-height:35px;
    max-width:inherit;
    margin: 10px 5px;
  }
}

}
// 

}

