/*
*******************************************
Template Name: Yourlife Insurance
Author: Ramees
Date: 26-02-2024
Template By: Vandalay Designs
Copyright 2020-2023 Vandalay Business Solution

* This file contains the styling for the TMS Legal, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************

*/

/*
============================================
==== T A B L E   O F   C O N T E N T S =====
============================================
01 - Sass Variables
02 - IMPORTS
03 - MIXIN
04 - FOOTER

05 - MEDIA QUERY

============================================
============================================

*/


/*================================================
01 - Sass Variables
==================================================*/
$colorPrimary: #0070ED;
$colorScondary: #00BCF9;
$colorBlack: #000;
$colorWhite: #fff;
$colorGray: #595959;
$colorlightGray: #ebebeb;



/*================================================
02 - IMPORTS
==================================================*/
// @import "bootstrap/scss/bootstrap";

/*FONTS*/



// * {
//   padding: 0;
//   margin: 0;
// }

p {
  font-size: 14px;
}

a {
  text-decoration: none;
}

ul,
li {
  padding: 0;
  margin: 0;
}

.text-justify {
  text-align: justify
}

.bg-gradient {
  background-image: linear-gradient(180deg, #1b9aaa, #1b9aaa6e) !important;

}

header {
  border-bottom: 1px solid #333;
}




$color1 : #1b9aaa;
$color2 : white;
$color_3: #111;
$background-color_1: #fee6e3;
$background-color_2: #111;
$background-color_3: #ffdeda;
$background-color_4: #106afd;


.top_sty {
  border-bottom: 1px solid #333;
  padding: 10px 0px;

  img {
    width: 17%;
  }
}

.wrap-content {
  max-width: 36rem;
  margin: 0 auto;
  min-height: 100vh;

  .logo_1 {
    width: 24%;
    padding: 10px 0px;
  }

  header {
    background: $color1;
    text-align: center;
    padding: 10px 0;
    color: $color2 ;

    h1 {
      font-weight: bolder;
      font-size: 27px;
      line-height: 1.5em;
      color: $color2 ;
    }

    p {
      margin: 0 auto;
      max-width: 33rem;
      font-size: 16px;

    }
  }

  .textc2 {
    font-weight: bold;
    padding: 5px 0;
  }

  .video-post {
    position: relative;

    .volume {
      position: absolute;
      left: auto;
      top: 18px;
      right: 18px;
      background: $color2;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      font-size: 22px;
      text-align: center;
      z-index: 99;
      cursor: pointer;
      border: 3px solid $color2;
      line-height: 32px;
      transition: all ease-in 0.5s;

      &:hover {
        border-color: $color2;
        background: $background-color_4;
        color: $color2;
        transition: all ease-in 0.5s;
      }
    }

    video {
      width: 100%;
      box-sizing: border-box;
      object-fit: cover;
      border: 3px solid $color1;
      padding: 0;
      margin: 0;
      //object-position: bottom;
      height: 520px;
      object-position: 25% 40%;
    }
  }

  .logodiv {
    img {
      height: 55px;
    }
  }

  .why_stay_covered {
    background-color: $color1;
    color: $colorWhite;
    text-align: center;

    h2 {
      font-size: 22px;
    }
  }

  .bottm-video-content {
    border: 2px solid #eee;

    .qs-inner {
      .regNextBtn {
        width: 212px;
        height: 64px;
        background-color: #ff9800;
        color: #fff;

        &:after {
          height: 62px;
        }

        &:hover {
          background-color: #ffaa2b;
        }
      }
    }

    .call-btn {
      align-items: center;
      background-color: $color1;
      border: 2px solid #111;
      border-radius: 60px;
      box-sizing: border-box;
      color: $color2;
      cursor: pointer;
      display: inline-flex;
      font-size: 32px;
      height: 85px;
      justify-content: center;
      line-height: 24px;
      max-width: 100%;
      padding: 30px;
      position: relative;
      text-align: center;
      width: 350px;
      font-weight: bold;
      text-decoration: none;
      user-select: none;
      -webkit-user-select: none;
      touch-action: manipulation;
      box-shadow: 4px 4px 0px -1px rgba(0, 0, 0, 0.96);
      -webkit-box-shadow:4px 4px 0px -1px rgba(0, 0, 0, 0.96);
      -moz-box-shadow: 4px 4px 0px -1px rgba(0, 0, 0, 0.96);

      &:hover {
        background-color: #008799;
        box-shadow: 4px 4px 0px -1px rgba(0, 0, 0, 0.96);
        -webkit-box-shadow:4px 4px 0px -1px rgba(0, 0, 0, 0.96);
        -moz-box-shadow: 4px 4px 0px -1px rgba(0, 0, 0, 0.96);

        outline: 0;
      }

      &:active {
        background-color: $color1;
        box-shadow: 4px 4px 0px -1px rgba(0, 0, 0, 0.96);
        -webkit-box-shadow:4px 4px 0px -1px rgba(0, 0, 0, 0.96);
        -moz-box-shadow: 4px 4px 0px -1px rgba(0, 0, 0, 0.96);
        outline: 0;
      }
    }

    .qs-slide {
      .qs-text {
        font-size: 20px
      }

      .form-check {
        margin: 10px 0;

        .form-check-input {
          height: 20px;
          width: 20px;
          border-radius: 3px;
          border: 1.5px solid #1b9aaa;

          &:checked {
            background-color: #0bbdd4 !important;
          }
        }

        label {
          padding-left: 10px !important;
          padding-top: 3px !important;
        }
      }

      .form-control {
        border: 1.5px solid #444444 !important;
        height: 50px !important;
        border-radius: 8px;
        font-size: 14px;
      }

      .form-select {
        font-weight: 300;
        color: #4b4b4b;
      }

      .form-label {
        font-size: 16px;
        font-weight: 600;
        color: #fff
      }

      .fw-700 {
        font-weight: 700 !important;
        font-size: 26px;
        color: #fff;
      }

      .qs-status {
        display: flex;
        gap: 10px;
        max-width: 250px;
        margin: 0 auto;
        margin-bottom: 25px;


        li {
          width: 100%;
          list-style-type: none;

          span {
            background: #eee;
            height: 8px;
            border-radius: 10px;
            width: 100%;
            display: block;

            &.active {
              background: $color1;
            }
          }
        }
      }

      .qs-loader {
        img {
          height: 50px;
        }
      }

      .custom-select {
        border: solid 2px #9d9999;
        height: 47px;
      }
    }


    .btn-comm {
      @extend .call-btn;
      width: 100%;
      padding: 0 15px;
      font-size: 18px;
      height: 50px;
      font-weight: 500;
      &.CALL_CLICK{
        font-size: 20px;
      }

    }
  }

  .trust_pi {
    .trust-sec-widget {
      margin-bottom: 10px;

      p {
        font-size: 16px;
        color: #111;

        .trustpi {
          height: 21px;
          margin-top: -2px;
        }
      }
    }

    .box-a {
      .trustpilot {
        img {
          // width: 180px;
          margin: 0px 0px 0px 47%;
        }
      }

      .icon_sec {
        p {
          font-size: 20px;
          line-height: 1em;
          margin: 0px 6px;
          color: #000;
        }
      }
    }
  }
}





// .text-message-item {
//   display: none !important;
// }

.slide-top {
  -webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
  animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;

}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(30px);
    transform: translateY(30px);
  }

  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

.body-inner {
  height: 100vh;
}

footer {
  float: left;
  width: 100%;
  padding-top: 50px;

  p {
    font-size: 14px;
  }

  a {
    // color: $colorBlack;
    text-decoration: none;
  }
}

.privacy_terms {
  padding: 40px 0;
  min-height: 80vh;

  p {
    font-size: 16px;
    line-height: 1.5;
  }

  ul {
    padding: 15px 15px 15px 30px;
  }

  .one_s {
    background-color: #03588b;
    color: #fff;
    padding: 15px 15px 15px 30px;
  }

  li {
    margin: 7px 0px;
    font-size: 15px;
  }

  .ml-3 {
    margin-left: 25px;
  }

  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    font-size: 16px;

    td {
      border: 1px solid #ddd;
      text-align: left;
      padding: 8px;
    }
  }
}

.text1 {
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 18px;
  font-weight: bold;
  color: #4b5563;
}

.text2 {
  font-size: 32px;
  color: #4b5563;
}

.support-online {
  span {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    box-shadow: 0px 0px 1px 1px #0000001a;
    display: inline-block;
    position: relative;
    top: 3px;
    background: #22c55e;
  }


  .pulse {
    animation: pulse-animation 1.5s infinite;
  }

  @keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px rgba(162, 221, 122, 0.616);
    }

    100% {
      box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
  }


}

@media screen and (max-width: 590px) {}

@media screen and (max-width: 490px) {

  .wrap-content {
    header {
      h1 {
        font-size: 24px;
        font-weight: 800;
      }

      p {
        font-size: 13px;
        padding: 0 10px;
      }

    }

    .logodiv {
      img {
        height: auto;
      }
    }

    .video-post {
      video {
        height: 300px;
        object-position: bottom;

        height: 320px;
        object-position: 25% 50%;
      }
    }

    .textc2 {
      font-size: 12px;
    }

    .bottm-video-content {
      padding: 0 15px;
      padding-top: px !important;
      border: none;

      .qs-slide {
        .qs-loader {
          img {
            height: 60px;
          }
        }

        .qs-status {
          li {
            span {
              height: 4px;
            }
          }
        }
      }

      .fw-700 {
        font-size: 20px !important;
      }

      .form-label {
        font-size: 14px !important;
      }

      .regNextBtn {
        margin-bottom: 20px !important;
      }

      .call-btn {
        width: 250px;
        font-size: 25px;
        height: 45px;

        &::after {
          height: 64px;
        }

      }

      .btn-comm {
        font-size: 20px;
        height: 60px;

      }

      .btn-me-1 {
        width: 100% !important;
        margin-bottom: 15px;

        .btn-comm {
          font-size: 14px;



        }
      }

      .btn-me-2 {
        width: 100% !important;
        margin-top: 15px;

        .btn-comm {
          font-size: 14px;



        }
      }


    }




  }

  footer {
    p {
      font-size: 12px;
    }
  }



}