.btn-anime-no-shadow-effect {
  box-shadow: 0 0 0 0 rgba(#5a99d4, .5);
  -webkit-animation: btn_anime_glowing 1.5s infinite;
  animation: btn_anime_glowing_no_shadow 1.5s cubic-bezier(0.57, 0.31, 0.36, 1) infinite;
}

@keyframes btn_anime_glowing_no_shadow {
  0% {
    transform: scale(.9);
  }

  70% {
    transform: scale(1);
    // box-shadow: 0 0 0 30px rgba(#5ad46e, 0);
  }

  100% {
    transform: scale(.9);
    // box-shadow: 0 0 0 0 rgba(#5ad46e, 0);
  }
}

.btn-anime {
  box-shadow: 0 0 0 0 rgba(#5a99d4, .5);
  -webkit-animation: btn_anime_glowing 1.5s infinite;
  animation: btn_anime_glowing 1.5s cubic-bezier(0.57, 0.31, 0.36, 1) infinite;
}

@keyframes btn_anime_glowing {
  0% {
    transform: scale(.9);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 30px rgba(#5ad46e, 0);
  }

  100% {
    transform: scale(.9);
    box-shadow: 0 0 0 0 rgba(#5ad46e, 0);
  }
}