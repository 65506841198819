.OV50_VSLQS_V1 {
  @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap');

  font-family: 'Roboto', sans-serif;
  font-size: 14px;

  @import 'custom';

  .form-check-input {
    cursor: pointer;
  }

  .error_msg {
    color: red;
    background-color: rgb(248, 205, 205);
    padding: 2px 5px;
    border-radius: 5px;
    margin-top: 5px;
  }

  .inner-banner {
    background: #b8b8b8 url(../../img/OV50_VSLQS_V1/bg-inner.jpg);
    background-size: cover;
    padding: 60px 0 80px 0;
    // margin-top: 88px;
    position: relative;

    &::after {
      content: "";
      background: url(../../img/OV50_VSLQS_V1/curve-bg.png) no-repeat;
      background-size: 100%;
      height: 106px;
      width: 100%;
      position: absolute;
      bottom: -5px;
    }

    h2 {
      font-size: 44px;
      color: $colorWhite;
    }

  }

  .visit-box {
    border-bottom: 4px solid $colorScondary;

    h3 {
      font-size: 24px;
      font-weight: bold;

      span {
        color: $colorScondary;
      }
    }
  }

  .voulnteersection {
    background: $colorlightGray;

    .volunteer-box {
      background: $colorWhite;
      border-radius: 10px;

      h2 {
        font-size: 20px;
        font-weight: bold;
      }
    }
  }

  .serv-grp {

    h2 {
      color: $colorPrimary;
      font-size: 24px;
    }

    h4 {
      font-size: 18px;
    }
  }


  .divide {
    background: $colorScondary;
    color: $colorWhite;
  }



  .card-props {
    position: relative;

    &:hover {
      .img-box {
        img {
          transform: scale(1.1);
          transition: all ease-in 0.2s;
        }

        .text-overlay {
          transform: translateY(0%);
          transition: all ease-in-out 0.4s;
        }
      }
    }

    .img-box {
      position: relative;
      border-radius: 10px;
      overflow: hidden;

      img {
        transform: scale(1);
        transition: all ease-in 0.2s;

      }

      .text-overlay {
        transition: all ease-in 0.4s;
        position: absolute;
        width: 100%;
        height: 100%;
        padding: 20px;
        background: rgb(0, 112, 237);
        background: linear-gradient(180deg, rgba(0, 112, 237, 0.6755077030812324) 0%, rgba(0, 188, 249, 1) 100%);
        top: 0;
        transform: translateY(100%);
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;

        h2 {
          color: $colorWhite;
          font-size: 22px;

        }
      }
    }
  }

  .slider-body {
    position: relative;

    .InitSlider {
      margin: 0 60px;

    }
  }

  .error-message {
    color: red;
  }



  .sec-divider {
    h2 {
      font-size: 26px;
      font-weight: bold;
      color: $colorPrimary;
    }
  }

  .bg-dote {
    background: url(../../img/OV50_VSLQS_V1/dote555.png) repeat-x;
    background: url(../../img/OV50_VSLQS_V1/dote555.png) repeat-x;
  }




  /*------------------------------------------------------------*/

  @media only screen and (max-width: 1200px) and (min-width: 960px) {
    .inner-banner {

      h2 {
        font-size: 30px;
      }

      &::after {
        height: 80px;
      }
    }

  }

  @media screen and (max-width: 960px) {

    .inner-banner {
      h2 {
        font-size: 26px;
      }

      padding: 0px 0 80px 0;

      .cirlc-img {
        max-width: 300px;
      }

      &::after {
        height: 64px;
      }
    }

  }


  @media screen and (max-width: 760px) {

    .inner-banner {
      .cirlc-img {
        max-width: 230px;
      }

      &::after {
        height: 48px;
      }
    }

    .visit-box {
      h3 {
        font-size: 18px;
      }
    }

    .card-props {
      .img-box {
        .text-overlay {
          h2 {
            font-size: 20px;
          }

        }
      }
    }

    .serv-grp {
      h2 {
        font-size: 20px;
      }
    }
  }



  @media screen and (max-width: 580px) {

    .inner-banner {

      //margin-top: 73px;
      &::after {
        height: 29px;
        bottom: -5px;
      }
    }
  }
}