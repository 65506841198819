.yourifeinsu {

	/*
*******************************************
Template Name: Your life insured
Author: Ramees R J
Date:17-04-2024
Template By: Vandalay Designs
Copyright 2020-2021 Vandalay Business Solution

* This file contains the styling for the Your life insured, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************


============================================
==== T A B L E   O F   C O N T E N T S =====
============================================
01 - Sass Variables
02 - Sass Mixins
03 - BODY 
04 - HEADER 
05 - SECTION 
06 - FOOTER 
07 - MODAL 
08 - MEDIA QUERY

============================================
============================================

*/


	/*================================================
01 - Sass Variables
==================================================*/

	// =======background colors========

	$background_color_1: #ffffff;
	$background_color_2: #dfdfdf;
	$background_color_3: #2e90af;
	$background_color_4: #f5f5f5;
	$background_color_5: #1b9aaa;


	// ======font styles===============

	$color_1: #ffffff;
	$color_2: #272727;
	$color_3: #303291;
	$color_4: #000;


	$font_family_1: 'Roboto',
	sans-serif !important;

	$font_size_1: 14px;
	$font_size_2: 11px;
	$font_size_3: 15px;
	$font_size_4: 21px;
	$font_size_5: 19px;
	$font_size_6: 16px;
	$font_size_7: 27px;
	$font_size_8: 22px;
	$font_size_9: 19px;
	$font_size_10: 18px;
	$font_size_11: 23px;
	$font_size_12: 34px;
	$font_size_13: 24px;
	$font_size_14: 20px;
	$font_size_15: 17px;


	$font-bold: bold;
	$font-normal: normal;
	$font-w500: 500;


	// ======border===========


	$border-style1: solid 1px #fff;



	$border-color1: #d3d3d3;
	$border-color2: #dedcdc;
	$border-color3: #000;


	/*================================================
02 - Sass Mixins
==================================================*/

	/*@mixin f_style1 {
	font-size: $font_size_1;
	color: $color_1;
	font-weight: $font-w500;
}
@mixin f_style2 {
	font-size: $font_size_5;
	color: $color_1;
	font-weight: $font-normal;
}
*/





	/*================================================
03 - BODY STYLES
==================================================*/


	html {
		scroll-behavior: smooth;
	}


	body {
		font-family: $font_family_1;
		font-size: $font_size_1;
		background: $background_color_1;
		color: $color_2 !important;
	}

	

	.cooki {
		font-size: $font_size_2;
		padding: 6px 0;
		position: fixed;
		z-index: 999;
		bottom: 0;
		border: 0px;
		background: $background_color_2;
		float: left;
		width: 100%;
		line-height: 11px;

		big {
			span {
				font-weight: $font-bold;
				color: red;
			}
		}

		span {
			color: #000;
			font-size: 12px;
			line-height: 16px;

			a {
				color: #000;
				font-size: 12px;
				font-weight: bold;
			}

		}

	}

	/*================================================
04 - HEADER 
==================================================*/

	header {
		padding-bottom: 15px;

		.logo {

			img {
				width: 50%;
				position: relative;
				top: 10px;
			}
		}

		.get_btn {
			background: $background_color_5;
			width: 50%;
			padding: 15px 10px !important;
			font-size: $font_size_11 !important;
			color: $color_1 !important;
			-webkit-box-shadow: 0 5px 0 0 #005d69;
			box-shadow: 0 5px 0 0 #005d69;

			&:hover {
				background-color: #22abbd;
			}
		}

		.advpara {
			font-size: 16px;

			p {
				margin-bottom: 0px;
				font-weight: bold;
			}
		}
	}

	/*================================================
05 - SECTION 
==================================================*/


	section {

		h1 {
			padding: 10px 0px;
			font-size: $font_size_12;
			font-weight: $font-bold;
			border-top: 1px solid $border-color1;
			border-bottom: 1px solid $border-color1;
		}

		.banner {
			width: 100%;
		}

		h2 {
			font-size: $font_size_8;
			margin-top: 15px;
			font-weight: $font-bold;
		}

		p {
			font-size: $font_size_6;
			font-weight: $font-normal;
		}

		.greensec {
			background-color: #dfeec5;
			border-bottom: 5px solid #a0c369;

			ul {
				li {
					font-size: 16px;
					font-weight: bold;
				}
			}
		}

		.cta_sec_dsk {
			.banner {
				h2 {
					color: $color_3;
					font-weight: $font-bold;
					font-size: $font_size_7;
					border-bottom: none;
				}

				h3 {
					b {
						background-color: #f5e62c;
						padding: 5px;
						border-radius: 15px;
					}
				}
				.live_animated{
					width: 18px !important;
					height: 18px;
					background-color: #ef2f02;
					border-radius: 100%;
					animation: pulse-animation2 1.5s infinite;
					display: inline-block;
					margin-right: 2px;
					position: relative;
				  }
				  @keyframes pulse-animation2 {
					0% {
					  box-shadow: 0 0 0 0px rgba(255, 0, 0, 0.644);
					}
					100% {
					  box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
					}
				  }

				p {
					font-weight: $font-bold;
					font-size: $font_size_8;
					text-align: center;
				}

				a {
					background-color: $background_color_5;
					width: 90px;
					padding: 0px 10px;
					margin: 5px 2px;
					line-height: 36px;
					text-decoration: none;
					color: $color_1 !important;
					border-radius: 42px;
					-webkit-box-shadow: inset 0px -5px 8px 0px rgba(0, 0, 0, 0.34);
					-moz-box-shadow: inset 0px -5px 8px 0px rgba(0, 0, 0, 0.34);
					box-shadow: inset 0px -5px 8px 0px rgba(0, 0, 0, 0.34);
					display: inline-block;

					&:hover {
						background-color: #22abbd;
					}
				}

				//			.banner-btn-color1 {
				//				background-color: $background_color_3 !important;
				//			}
				//             .banner-btn-color1 {
				//    			background: rgba(0,95,139,1);
				//    			background: -moz-linear-gradient(top, rgba(0,95,139,1) 0%, rgba(3,145,180,1) 100%);
				//    			background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(0,95,139,1)), color-stop(100%, rgba(3,145,180,1)));
				//    			background: -webkit-linear-gradient(top, rgba(0,95,139,1) 0%, rgba(3,145,180,1) 100%);
				//    			background: -o-linear-gradient(top, rgba(0,95,139,1) 0%, rgba(3,145,180,1) 100%);
				//    			background: -ms-linear-gradient(top, rgba(0,95,139,1) 0%, rgba(3,145,180,1) 100%);
				//    			background: linear-gradient(to bottom, rgba(0,95,139,1) 0%, rgba(3,145,180,1) 100%);
				//    			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#005f8b', endColorstr='#0391b4', GradientType=0 );
				//  			}
				//  			.banner-btn-color2 {
				//    			background: rgba(11,110,132,1);
				//    			background: -moz-linear-gradient(top, rgba(11,110,132,1) 0%, rgba(42,168,158,1) 100%);
				//    			background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(11,110,132,1)), color-stop(100%, rgba(42,168,158,1)));
				//    			background: -webkit-linear-gradient(top, rgba(11,110,132,1) 0%, rgba(42,168,158,1) 100%);
				//    			background: -o-linear-gradient(top, rgba(11,110,132,1) 0%, rgba(42,168,158,1) 100%);
				//    			background: -ms-linear-gradient(top, rgba(11,110,132,1) 0%, rgba(42,168,158,1) 100%);
				//    			background: linear-gradient(to bottom, rgba(11,110,132,1) 0%, rgba(42,168,158,1) 100%);
				//    			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#0b6e84', endColorstr='#2aa89e', GradientType=0 );
				//  			}
				//  			.banner-btn-color3 {
				//    			background: rgb(199, 226, 217);
				//    			background: -moz-linear-gradient(top, rgba(53,134,108,1) 0%, rgba(86,194,131,1) 100%);
				//    			background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(53,134,108,1)), color-stop(100%, rgba(86,194,131,1)));
				//    			background: -webkit-linear-gradient(top, rgba(53,134,108,1) 0%, rgba(86,194,131,1) 100%);
				//    			background: -o-linear-gradient(top, rgba(53,134,108,1) 0%, rgba(86,194,131,1) 100%);
				//    			background: -ms-linear-gradient(top, rgba(53,134,108,1) 0%, rgba(86,194,131,1) 100%);
				//    			background: linear-gradient(to bottom, rgba(53,134,108,1) 0%, rgba(86,194,131,1) 100%);
				//    			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#35866c', endColorstr='#56c283', GradientType=0 );
				//  			}
				//  			.banner-btn-color4{
				//    			background: rgba(96,158,83,1);
				//    			background: -moz-linear-gradient(top, rgba(96,158,83,1) 0%, rgba(96,199,125,1) 100%);
				//    			background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(96,158,83,1)), color-stop(100%, rgba(96,199,125,1)));
				//    			background: -webkit-linear-gradient(top, rgba(96,158,83,1) 0%, rgba(96,199,125,1) 100%);
				//    			background: -o-linear-gradient(top, rgba(96,158,83,1) 0%, rgba(96,199,125,1) 100%);
				//    			background: -ms-linear-gradient(top, rgba(96,158,83,1) 0%, rgba(96,199,125,1) 100%);
				//    			background: linear-gradient(to bottom, rgba(96,158,83,1) 0%, rgba(96,199,125,1) 100%);
				//    			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#609e53', endColorstr='#60c77d', GradientType=0 );
				//  			}
				//  			.banner-btn-color5 {
				//    			background: rgba(88,154,86,1);
				//    			background: -moz-linear-gradient(top, rgba(88,154,86,1) 0%, rgba(53,174,152,1) 100%);
				//    			background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(88,154,86,1)), color-stop(100%, rgba(53,174,152,1)));
				//    			background: -webkit-linear-gradient(top, rgba(88,154,86,1) 0%, rgba(53,174,152,1) 100%);
				//    			background: -o-linear-gradient(top, rgba(88,154,86,1) 0%, rgba(53,174,152,1) 100%);
				//    			background: -ms-linear-gradient(top, rgba(88,154,86,1) 0%, rgba(53,174,152,1) 100%);
				//    			background: linear-gradient(to bottom, rgba(88,154,86,1) 0%, rgba(53,174,152,1) 100%);
				//    			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#589a56', endColorstr='#35ae98', GradientType=0 );
				//  			}	
				//  			.banner-btn-color6 {
				//    			background: rgba(42,131,118,1);
				//    			background: -moz-linear-gradient(top, rgba(42,131,118,1) 0%, rgba(11,149,176,1) 100%);
				//    			background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(42,131,118,1)), color-stop(100%, rgba(11,149,176,1)));
				//    			background: -webkit-linear-gradient(top, rgba(42,131,118,1) 0%, rgba(11,149,176,1) 100%);
				//    			background: -o-linear-gradient(top, rgba(42,131,118,1) 0%, rgba(11,149,176,1) 100%);
				//    			background: -ms-linear-gradient(top, rgba(42,131,118,1) 0%, rgba(11,149,176,1) 100%);
				//    			background: linear-gradient(to bottom, rgba(42,131,118,1) 0%, rgba(11,149,176,1) 100%);
				//    			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2a8376', endColorstr='#0b95b0', GradientType=0 );
				//  			}
				//  			.banner-btn-color7 {
				//    			background: rgba(3,108,140,1);
				//    			background: -moz-linear-gradient(top, rgba(3,108,140,1) 0%, rgba(0,136,181,1) 100%);
				//    			background: -webkit-gradient(left top, left bottom, color-stop(0%, rgba(3,108,140,1)), color-stop(100%, rgba(0,136,181,1)));
				//    			background: -webkit-linear-gradient(top, rgba(3,108,140,1) 0%, rgba(0,136,181,1) 100%);
				//    			background: -o-linear-gradient(top, rgba(3,108,140,1) 0%, rgba(0,136,181,1) 100%);
				//    			background: -ms-linear-gradient(top, rgba(3,108,140,1) 0%, rgba(0,136,181,1) 100%);
				//    			background: linear-gradient(to bottom, rgba(3,108,140,1) 0%, rgba(0,136,181,1) 100%);
				//    			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#036c8c', endColorstr='#0088b5', GradientType=0 );
				//  			}
			}
		}

		.blue_btn {
			padding: 15px !important;
			width: 45%;
			font-size: $font_size_9 !important;
			font-weight: $font-bold !important;
			margin-bottom: 20px !important;
			background: $background_color_3;
			text-transform: uppercase;
			color: $color_1 !important;
			border-radius: 0.25rem;
			margin-top: 15px;

			&:hover {
				background-color: #015d79;
			}
		}

		.side_ban {
			width: 100%;
		}

	}

	.sidebar {
		display: inline-block;
		vertical-align: top;
	}

	.fixedsticky {
		top: 25px;
		position: -webkit-sticky;
		position: -moz-sticky;
		position: -ms-sticky;
		position: -o-sticky;
		position: sticky;
	}

	.fixedsticky-withoutfixedfixed {
		.fixedsticky-off {
			position: static;
		}

		.fixedsticky-on {
			position: fixed;
		}
	}

	.fixed-supported {
		.fixedsticky-off {
			position: static;
		}

		.fixedsticky-on {
			position: fixed;
		}
	}

	.fixedsticky-dummy {
		display: none;
	}

	.fixedsticky-on {

		&+.fixedsticky-dummy {
			isplay: block;
		}
	}



	/*================================================
06 - section fut 
==================================================*/
	.fut {
		background: #404042;
		color: #fff;
		padding: 23px 0px 17px;
		font-size: 16px;

		.logo1 {
			img {
				width: 20%;
			}
		}

		.copyright {
			width: 100%;
			margin: 0px auto 10px;
			text-align: center;
			font-size: 14px;
			font-weight: normal;
			line-height: 1.45em;
			color: #fff;
		}
	}


	/*================================================
07 - footer 
==================================================*/

	footer {
		width: 100%;
		height: auto;
		padding-top: 22px;
		background: #dfe5ea;

		.spine {
			margin: 0 auto;
			padding: 0;
			width: 83.5%;
			height: auto;

			p {
				font-size: 14px;
				float: none;
				text-align: center;
				margin-bottom: 6px;
			}
		}

		ul {
			text-align: center;
			margin: 5px auto;
			padding: 0;

			li {
				margin: 0 3px;
				padding: 0;
				display: inline;
				vertical-align: baseline;
				font-size: 15px;
				color: #898c8d;
				cursor: pointer;
				text-decoration: none;

				a {
					color: #222222 !important;
					cursor: pointer;
					text-decoration: none;
				}
				.text-dark{
					color: #222222 !important;
				}
			}
		}
	}


	/*================================================
07 - MODAL 
==================================================*/

	.closecook {
		cursor: pointer;
	}

	.modal {
		p {
			font-weight: normal !important;
		}

		.modal-content {
			border: none !important;

			.modal-header {

				h4 {
					font-size: $font_size_8;
				}
				.close {
					background: #fff;
					border: none;
					font-size: 30px;
					color: #000;
					width: 100%;
					text-align: end;
				}
			}
		}

		.modal-body {

			h5 {
				font-size: $font_size_10;
			}
		}

		

		table {
			border-collapse: collapse;
		}

		tr {
			border-bottom: 1px solid #000;
		}

		td {
			border-right: 1px solid #000;
			padding: 5px;
		}

		.modal-footer {
			border-top: 1px solid #dee2e6;
		}

	}


	/*================================================
08 - MEDIA QUERY 
==================================================*/





	$break-min-ipad: 768px;
	$break-max-ipad: 990px;

	$break-min-tab: 577px;
	$break-max-tab: 767px;

	$break-mob: 576px;




	@media (min-width:$break-min-ipad) and (max-width:$break-max-ipad) {

		header {
			.get_btn {
				width: 70%;
			}

			.logo {

				img {
					width: 70%;
					position: relative;
					top: 18px;
				}
			}
		}


		section {

			.cta_sec_dsk {

				.banner {

					a {
						width: 57px;
					}
				}
			}
		}



	}


	@media (min-width:$break-min-tab) and (max-width:$break-max-tab) {

		header {
			padding-top: 15px;

			.logo {
				img {
					width: 100%;
					top: 16px;
				}
			}

			.get_btn {
				width: 100%;
			}
		}

		section {

			h1 {
				font-size: $font_size_13;
			}

			h2 {
				font-size: $font_size_14;
			}

			.cta_sec_dsk {

				.banner {

					a {
						width: 57px;
					}
				}
			}

		}


	}



	@media screen and (max-width: $break-mob) {


		header {
			.logo {

				img {
					width: 100%;
				}
			}

			.get_btn {
				width: 100%;
				padding: 7px 10px !important;
				font-size: $font_size_15 !important;
			}
		}

		section {
			h1 {
				font-size: 27px !important;
			}

			h2 {
				font-size: $font_size_15;
			}

			p {
				font-size: $font_size_3;
			}

			.cta_sec_dsk {

				.banner {

					a {
						width: 68px;
					}
				}
			}

			.blue_btn {
				width: 85%;
			}
		}

		.fut {
			.logo1 {
				img {
					width: 40% !important;
				}
			}
		}
	}

}