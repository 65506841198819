// background-color: #008799;
.btn-anime{
  background-color: #008799 !important;
  width: 30% !important;
}
.modal-content{
  border: 3px solid #008799 !important;
}
.modal-footer{
  border: 0px;
}