
.chat-section {
  width: 100%;
  float: left;
  .chat-wrap {
    max-width: 450px;
    margin: 0 auto;
  }
  .curs_pointer{ 
    cursor: pointer !important;
  }
  .chat-box-inner {
    position: relative;
    z-index: 5px;
    min-height: 400px;
    padding: 10px;
    overflow: hidden;
    .row-chart-message {
      font-family: Arial, Helvetica, sans-serif;
      .chater-prof {
        display: flex;
        align-items: flex-end;
        max-width: 90%;
        .text-msg {
          background: #e5e7eb;
          padding: 10px 15px;
          margin:5px 0 0 0;
          display: inline-block;
          border-radius: 5px;
          font-size: 15px;

          &.btn-spc {
            padding: 20px 10px;
          }

          ul{
            margin-top: 10px;
            margin-left: -14px;
            li{
              list-style: disc;
            }
          }
        }
        .caption-2{
          width: 100%;
          text-align: center;
          font-size: 16px;
          font-weight: 600;
          margin: 20px 0;
          color: #4f5660;
          // border-left: solid 8px #3b82f6;
          // border-right: solid 8px #3b82f6;
          border-radius: 5px;
        }
        .w-100{
          width: 100%;
        }
        .call_now {
          background-color: #e5e7eb;
          padding: 18px 15px;
          border-radius: 5px;
          .callbtn{
              background-color: #3b82f6;
              padding: 13px 12px;
              width: 60%;
              margin-left: auto;
              margin-right: auto;
              border-radius: 8px;
              color: #fff;
              font-weight: bold;
              font-size: 18px;
              &:hover{
                background-color: #3877dd;
              }
          }
        }

        .phone {
          font-size: 16px;
          padding:10px 20px;
         }
        .qs-inner{
          background: #e5e7eb;
          .qs-text{
            text-align: center;
            font-size: 22px;
          }
        }
        .qs-inner1{
          background: #e5e7eb;
          .qs-text{
            text-align: center;
            font-size: 22px;
          }
        }
        .qs-inner2{
          background: #e5e7eb;
          .qs-text{
            text-align: center;
            font-size: 22px;
          }
        }
        .mesg19{
          margin-top: 7px;
        }
        .contact_dt{
          width: 100%;
          margin-top: 7px;
          .action-btn{
            background-color: #3b82f6;
            border-radius: 6px;
            color: #fff;
            font-weight: bold;
            font-size: 19px;
            padding: 15px 37px;
            border: none;
            &:hover{
              background-color: #3172d9;
            }
          }
          .qs-inner{
            form{
              .selectww{
                color: #706d6d !important;
                font-size: 14px !important;
                padding: 10px 10px !important;
              }
            }
          }
          .qs-inner1{
            form{
              .selectww{
                color: #706d6d !important;
                font-size: 14px !important;
                padding: 10px 10px !important;
              }
            }
          }
          .qs-inner2{
            form{
              .selectww{
                color: #706d6d !important;
                font-size: 14px !important;
                padding: 10px 10px !important;
              }
            }
          }
        }
        .new_text{
          p{
            font-size: 15px !important;
          }
        }
      }
     
      
     
      .msg-loader {
        img {
          height: 15px;
          
        }
      }
    }
    .supporter-dp {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #ddd;
      display: block;
      margin-right: 10px;
      text-align: center;
      border: 1px solid #ddd;
      overflow: hidden;
      img {
 
        height: 38px;
       
        
      }
    }
    .sel-option {
         
       
      .action-btn {
        background:   #3b82f6;
        padding:15px 40px;
        margin-top: 20px;
        // width:96%;
        color: #fff;
        -webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        border-radius: 7px;
        margin-left: auto;
        margin-right: auto;
        font-weight: bold;
        transition: ease-in 300ms;
        &.answ-bnt {
          padding: 15px 20px;
        }

        &:hover{ 
          background: rgb(63, 137, 255);
        }
       
      }


      .btn-lookup {
        background:   #3b82f6;
        padding:10px 10px;
        width:100%;
        color: #fff;
        text-align: center;
        border: 0px;
        -webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        border-radius: 7px;
        margin-left: auto;
        margin-right: auto;
        font-weight: bold;
        transition: ease-in 300ms;
        &.answ-bnt {
          padding: 15px 20px;
        }

        &:hover{ 
          background: rgb(63, 137, 255);
        }
       
      }













      .action-btn-submit { 
        background-color:  #ff8759;;
        margin-top: 12px;
        &:hover{ 
          background-color:  #ff8759;;
        }
      }
      
      .b-b{
        border-bottom: solid 5px #1760d8;
        margin-top: 5px;
      }
   
      .ibox{
        position: absolute;
        left: 3px;
        background-color: #1f65d6;
        padding: 10px;
        top: 3px;
        border-radius: 7px;
        img{
          animation: tilt-shaking 0.25s linear infinite;
        }
        @keyframes tilt-shaking {
          0% { transform: rotate(0deg); }
          25% { transform: rotate(5deg); }
          50% { transform: rotate(0eg); }
          75% { transform: rotate(-5deg); }
          100% { transform: rotate(0deg); }
        }
      }
    .selectww{
      color: #b4b9bd !important;
    }
    .form-control{
      height:55px;
      border: solid 2px #c3c3c3;
      border: 1px #3b82f6 solid;
      border-bottom: 5px solid #3b82f6;
      border-radius: 5px;
    }
    .form-select{
    height:55px;
    border: solid 2px #c3c3c3;
    border: 1px #3b82f6 solid;
    border-bottom: 5px solid #3b82f6;
    border-radius: 5px;
}




    }

    .client-replay {
      text-align: right;
      margin-top: 10px;

      .client-replay-vpx {
        display: flex;
        justify-content: end;
        align-items: center;
          .dp-client {
            @extend .supporter-dp;
            order: 2;
            margin-right: 0;
            margin-left: 10px;
            
          }

      }
  }
  }

 }





.slide-top {
	-webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-top {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-5px);
              transform: translateY(-5px);
    }
  }
  @keyframes slide-top {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-5px);
              transform: translateY(-5px);
    }
  }


.text1 {
   font-family: Georgia, 'Times New Roman', Times, serif;
   font-size: 18px;
   font-weight: bold;
   color: #4b5563;
}
.text2 {
  font-size: 32px;
  color: #4b5563;
  padding: 0 5%;
}
.text3 {
  padding: 0 5%;
}
.support-online {
  font-size: 16px;
   span {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    box-shadow: 0px 0px 1px 1px #0000001a;
    display: inline-block;
    position: relative;
    top: 3px;
     background: #22c55e;
  }
   

  .pulse {
    animation: pulse-animation 1.5s infinite;
  }
  
  @keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px rgba(162, 221, 122, 0.616);
    }
    100% {
      box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
  }
  
  
}
.cursor_pointer{
  cursor: pointer;
}

  @media screen and (max-width: 760px) {
       
  
    .chat-section {
      .chat-box-inner {
        .sel-option {
          .action-btn {
            padding: 15px 30px;
          }
        }
      }
    }

    .text1 {
      font-size: 14px;
    }
    .text2{
      font-size: 26px;
    }

    .mobile_view{
      width: 100%;
      display: block;
      margin-bottom: 15px;
    }
    
  }

  @media screen and (max-width: 490px) {
    .text2 {
      font-size: 26px;
  }

    .chat-section{
      .chat-box-inner{
        .row-chart-message{
          .chater-prof{
            .qs-inner{
              padding: 21px 0px;
            }
          }
        }
        .sel-option{
          .form-check{
            label{
              height: 72px;
            }
            .label_adj1{
              padding-top: 13px !important;
            }
            .label_adj2{
              padding-top: 23px !important;
            }
            .form-check-input{
              height: 20px;
              margin-top: 24px;
              width: 19px;
            }
          }
        }
        .sel-option{
            .partbtn{
              padding: 15px 7px;
            }
        }
      }
    }
  }







