@import "node_modules/bootstrap/scss/bootstrap";
// @import "node_modules/bootstrap";
@import "bootstrap-icons/font/bootstrap-icons.css";
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,300;0,400;0,600;0,700;1,400&display=swap");
@import "./assets/css/NotFound.scss";
@import './assets/css/Modal.scss';

body {
  font-family: Titillium Web, sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #000;
}
