// @import "node_modules/bootstrap/scss/bootstrap";

.OV50_GPOS_V3 {
    @import url('https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900');

    font-family: 'Lato', sans-serif !important;
    background: #ddd !important;

    /*
*******************************************
Template Name: yourlife_insured
Author:Nikhil Emmanuel
Date: 29-04-2022
Template By: Vandalay Designs
Copyright 2020-2021 Vandalay Business Solution

* This file contains the styling for the yourlife_insured, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************


============================================
==== T A B L E   O F   C O N T E N T S =====
============================================
01 - Sass Variables
02 - Sass Mixins
03 - BODY
04 - SECT
05 - section fut
06 - FOOTER
07 - MODAL
08 - Animation
09 - MEDIA QUERY

============================================
============================================

*/


    /*================================================
01 - Sass Variables
==================================================*/

    $background_color_1: #fff;
    $background_color_2: #ddd;
    $background_color_3: #f8f8f8;
    $background_color_4: #2f9de8;
    $background_color_5: #3ebbe9;
    $background_color_6: #F0652F;
    $background_color_7: #404042;
    $background_color_8: #cccccc4a;
    $background_color_9: #0db955;
    $background_color_10: #1078bf;
    $background_color_11: #2e9ee8;
    $background_color_12: #0b9244;




    $color_1: #fff;
    $color_2: #155577;
    $color_3: #777777;
    $color_4: #445870;
    $color_5: #0070ba;
    $color_6: #a7a7a7;
    $color_7: #000;
    $color_8: #006ebe;
    $color_9: #828282;
    $color_10: #898c8d;
    $color_11: #09853c;
    $color_12: #0db955;
    $color_13: #0b5080;
    $color_14: #0a5028;



    $font-normal: normal;
    $font-bold: bold;
    $font-w700: 700;
    $font-w600: 600;
    $font-w400: 400;

    $font_family_1: 'Lato', sans-serif;

    $font_size_1: 16px;
    $font_size_2: 14px;
    $font_size_3: 44px;
    $font_size_4: 17px;
    $font_size_5: 15px;
    $font_size_6: 18px;
    $font_size_7: 12px;
    $font_size_8: 20px;
    $font_size_9: 28px;
    $font_size_10: 32px;
    $font_size_11: 30px;
    $font_size_12: 25px;
    $font_size_13: 22px;
    $font_size_14: 19px;
    $font_size_15: 26px;
    $font_size_16: 24px;



    // ======border===========
    $border-style1: 2px solid #d9d9d9;
    $border-style2: 3px solid #047e37;
    $border-style3: 1px solid #fff;
    $border-style4: solid 5px #e8e8e8;

    $color1_blue : #1b9aaa;
    $colorWhite: #fff;

    /*================================================
02 - Sass Mixins
==================================================*/

    /*================================================
03 - BODY STYLES
==================================================*/

    &::before {
        position: absolute;
        top: 80px;
        right: 0;
        width: 100%;
        height: 346px;
        background-image: url(../../img/OV50_GPOS_V3/bannersbg.jpg);
        content: "";
        z-index: 0;
    }

    .hide {
        display: none;
    }

    .z-index-1 {
        z-index: 1;
    }

    .text-white {
        color: white !important;
    }

    .progress-bar {
        width: 20%;
        background: $color1_blue !important;
    }

    a {
        color: #007bff !important;
        cursor: pointer;
    }

    header {
        padding: 15px;
        background: $background_color_1;

        .logo {
            height: 65px;
        }
    }


    /*================================================
04 - SECT
==================================================*/

    // label {
    //     color: $color_3;
    //     font-size: $font_size_6;
    //     font-weight: $font-w700;
    //     line-height: 24px;
    //     width: 100%;
    //     display: inline-block;
    //     margin-top: 15px;
    //     text-align: center;

    //     input[type="radio"] {
    //         display: none !important;
    //     }
    // }
    .img-style{
        position: relative;
        margin: -20px 0 0 -10px;
        height: 22px;
        @media screen and (max-width: 576px) {
            height: 15px;
        }
    }

//     @media screen and (max-width: 576px) {
//     .pupular-label{
//         font-size: 12px;  
//     }

// }

    input[type="checkbox"]:checked {
        background-color: $color1_blue;
    }
    input[type="checkbox"] {
        border: 2px solid $color1_blue;
    }

    .final-question-parentClass{
        h3{
            font-size: 22px !important;
        }
        .dummyText.final-question-dummyText{
            font-size: 18px !important;
        }
    }
    legend {
        padding: 0 10px;
        display: block;
        float: none;
        width: auto !important;
        max-width: 100%;
        margin-bottom: 0px !important;
        font-size: 16px;
        line-height: inherit;
        color: #777;
        font-weight: 700;
    
    }
    fieldset {
        border: 2px solid #a9b1c9;
        padding: 10px;
        border-radius: 0px;
        margin-bottom: 15px;
    }

    .body-section {
        min-height: 80vh;
        background: #fdfdfd;
    }

    .form_part {
        background: $background_color_1;
        border-radius: 5px;
        -webkit-box-shadow: 0 1px 7px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .37);
        box-shadow: 0 1px 7px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .37);
        padding: 20px 0;
        width: 80%;
        margin: 20px auto;
        padding: 0px;

        h1 {
            font-size: $font_size_3;
            color: $color_2 !important;
            font-weight: $font-w700;
            text-align: center;
            padding: 15px 0px 0px;
            margin-bottom: 0px;
        }

        h2 {
            margin: 0px 0px 15px;
            font-size: 23px;
            text-align: center;
        }

        .my-bts {
            margin: 10px 0px !important;
            font-size: $font_size_9;
            border: none;
            border-radius: 5px;
            border-bottom: 3px solid #13717d;
            color: $color_1;
            font-weight: $font-w700 !important;
            padding: 15px 0;
            width: 55%;
            background: $color1_blue;

            &.CALL_CLICK{
                font-size: 22px;
            }
        }

        .logosec {
            // background: $background_color_8;

            .insurer-logos {
                text-align: center;

                img {
                    display: inline-block;
                    width: 70%;
                    // padding: 0 1%;
                    // -webkit-filter: grayscale(100%);
                    // filter: grayscale(100%);
                }
            }
        }

        .formpartsec {
            color: $color1_blue !important;

            .headspan {
                color: #0e545d;
            }
        }

        .form-control {
            background-color: $background_color_1;
            border: $border-style1;
            width: 90%;
            border-radius: 5px;
            display: inline-block;
            margin-top: 5px;
            position: relative;
            cursor: pointer;
            box-sizing: border-box;
            height: 55px;
            font-size: $font_size_1;
            font-weight: $font-w400;
            width: 100%;
            padding: 12px 30px 12px 15px;
            color: $color_4;
        }

        .logos {
            background-color: $background_color_3;
            padding: 10px -15px !important;
            position: relative;
            margin: 10px 0;
            padding: 0px 8%;

            .providers {
                text-align: center;
                padding: 0px;

                .providers-item {
                    display: inline-block;
                    list-style: none;
                    text-align: center;
                    padding: 10px;

                    img {
                        width: 70%;
                        border: medium none;
                        margin-right: 10px;
                    }
                }
            }
        }

        .form-group {
            width: 100%;
            margin-bottom: 7px;


            .btn-comm {
                border: none;
                background-color: $color1_blue;
                border-bottom: 3px solid #13717d;
                font-size: 16px;
                color: #fff;
                font-weight: 600;
                box-sizing: border-box;
                width: 100%;
                margin: 0 auto;
                cursor: pointer;
                position: relative;
                overflow: hidden;
                text-align: center;
                padding: 20px 15px;

                &.active {
                    background-color: #13717d;
                    border-bottom: 3px solid #0e545d;
                }

                .form-check {
                    text-align: left !important;
                    margin: 10px 0;

                    .form-check-input {
                        height: 20px;
                        width: 20px;
                        border-radius: 3px;
                        border: 1.5px solid #1b9aaa;

                        &:checked {
                            background-color: #0bbdd4 !important;
                        }
                    }

                    // label {
                    //     padding-left: 10px !important;
                    //     padding-top: 3px !important;
                    // }
                }
            }

            .mar {
                margin: 0 auto;
                text-align: center;
            }
        }


        #slide6 {
            .icon_option1 {
                background: $background_color_9;
                border-bottom: $border-style2;
                font-size: $font_size_1;
                color: $color_1;
                font-weight: $font-w600;
                box-sizing: border-box;
                width: 30%;
                margin: 0 5px 0 auto;
                cursor: pointer;
                position: relative;
                overflow: hidden;
                text-align: center;
                padding: 12px 15px;
            }

            .icon_option1.active {
                border-color: $color_14;
                background-color: $background_color_12;
                z-index: 100;
                color: $color_1;
            }

            .icon_buttons {
                width: 100%;
                vertical-align: top !important;
                text-align: center;
                margin: 5px auto 0;
                padding-left: 0%;

                li {
                    list-style: none !important;
                    width: 100%;
                    text-align: center;
                }
            }

        }


        #slide1,
        #slide10,
        #slide2 {
            .icon_option {
                background: $background_color_9;
                border-bottom: $border-style2;
                font-size: $font_size_1;
                color: $color_1;
                font-weight: $font-w600;
                box-sizing: border-box;
                width: 75%;
                margin-top: 15px;
                margin: 0 auto;
                cursor: pointer;
                position: relative;
                overflow: hidden;
                text-align: center;
                padding: 20px 15px;

                span {
                    display: inline-block;
                    vertical-align: middle;
                }
            }

            .icon_option.active {
                border-color: $color_14;
                background-color: $background_color_12;
                z-index: 100;
                color: $color_1;
            }

        }


        .options {
            margin-bottom: 0px !important;
        }

        .form-check {
            display: grid;
            grid-template-columns: auto 1fr;
            gap: 10px;
        }

        .slide-heading {
            color: #777;
            font-size: 18px;
            font-weight: 700;
            line-height: 24px;
            width: 100%;
            display: inline-block;
            margin-top: 15px;
            text-align: center;
        }

        .form-check-input {
            margin: 10px 0;
            display: block;
            min-height: 1.5rem;
            padding-left: 1.5em;
            width: 20px;
        }

        .form-check-label {
            display: flex;
            align-items: center;
            color: #777777;
            font-size: 16px;
            font-weight: 700;
            width: 100%;
            padding-left: 10px;
            padding-top: 3px;
            @media screen and (max-width: 576px) {
                font-size: 15px;
            }
        }

        #slide4 {
            .icon_option1 {
                background: $background_color_9;
                border-bottom: $border-style2;
                font-size: $font_size_1;
                color: $color_1;
                font-weight: $font-w600;
                box-sizing: border-box;
                width: 44%;
                margin: 0 5px 0 auto;
                cursor: pointer;
                position: relative;
                overflow: hidden;
                text-align: center;
                padding: 12px 15px;
            }

            .icon_option1.active {
                border-color: $color_14;
                background-color: $background_color_12;
                z-index: 100;
                color: $color_1;
            }

            .icon_buttons {
                width: 100%;
                vertical-align: top !important;
                text-align: center;
                margin: 5px auto 0;
                padding-left: 0%;

                li {
                    list-style: none !important;
                    width: 100%;
                    text-align: center;
                }
            }

        }

        #slide5 {
            .icon_option1 {
                background: $background_color_9;
                border-bottom: $border-style2;
                font-size: $font_size_1;
                color: $color_1;
                font-weight: $font-w600;
                box-sizing: border-box;
                width: 20%;
                margin: 0 5px 0 auto;
                cursor: pointer;
                position: relative;
                overflow: hidden;
                text-align: center;
                padding: 12px 15px;
            }

            .icon_option1.active {
                border-color: $color_14;
                background-color: $background_color_12;
                z-index: 100;
                color: $color_1;
            }

            .icon_buttons {
                width: 100%;
                vertical-align: top !important;
                text-align: center;
                margin: 5px auto 0;
                padding-left: 0%;

                li {
                    list-style: none !important;
                    width: 100%;
                    text-align: center;
                }
            }
        }

        .mobpad10 {
            margin-top: 5px !important;
        }

        .authorise {
            p {
                font-size: $font_size_5;
            }
        }

        .consent {
            padding-bottom: 20px;

            p {
                font-size: $font_size_5;
                color: $color_7;
            }
        }

        .safe-text {
            font-size: $font_size_7;
            color: $color_6;
        }

        .bord {
            padding: 0px 0px 15px;
            margin-top: 0px;


            .my-bt {
                margin: 10px 0px !important;
                font-size: $font_size_9;
                border-radius: 5px;
                color: $color_1;
                font-weight: $font-w700 !important;
                padding: 12px 0;
                width: 55%;
                -webkit-box-shadow: 0px 4px 0px 0px #0a873e;
                -moz-box-shadow: 0px 4px 0px 0px #0a873e;
                box-shadow: 0px 4px 0px 0px #0a873e;
                background: $background_color_9;
            }


            .my-bts {
                margin: 10px 0px !important;
                font-size: $font_size_9;
                border-radius: 5px;
                color: $color_1;
                font-weight: $font-w700 !important;
                padding: 15px 0;
                width: 55%;
                background: $background_color_9;
            }

            .my-btttnes {
                margin: 10px 0px !important;
                font-size: $font_size_9;
                border-radius: 5px;
                color: $color_1;
                font-weight: $font-w700 !important;
                padding: 12px 0;
                width: 55%;
                -webkit-box-shadow: 0px 4px 0px 0px #0a873e;
                -moz-box-shadow: 0px 4px 0px 0px #0a873e;
                box-shadow: 0px 4px 0px 0px #0a873e;
                background: $background_color_9;
            }

            .back_btn {
                font-size: $font_size_8;
                color: $color_8 !important;
                padding: 10px 0;
                cursor: pointer;
            }

        }

        .Lookup_but {
            background: $background_color_9;
            padding: 12px 14px;
            font-size: $font_size_12;
            color: $color_1 !important;
            text-align: center;
            width: 50%;
            margin-bottom: 15px;
        }

        .para {
            font-size: $font_size_4;
            color: $color_3;
        }

        .paragrap {
            font-size: $font_size_1;
        }

        .yellow {
            background: yellow;
        }
    }

    .input-group-text {
        padding: 0px 5px !important;
        height: 55px !important;
        border-radius: 0px 5px 5px 0px !important;
        top: 5px;
        position: relative;
    }


    .listbx{background-color:none;
        padding: 20;
        color: #fff;
    }

    .listbx_desk{background-color:none;
        padding: 20;
        ul{
            padding: 0px;
            text-align: center;
            
            li{
                display: inline-block;
                list-style-type: disc;
                font-size: 16px;
                padding: 0 15px;
                background:url(../../img/OV50_GPOS_V3/circle_li.png) no-repeat left center;
                background-size: 9px;
            }
        }
    }

    //custom_callBtn
    .buttonBounce {
        animation: bounce 1.5s infinite;
        animation-timing-function: ease-in;
        transition: .2s;
        -webkit-animation: bounce 1.5s infinite;
    }//buttonBounce
    @keyframes bounce {
        15% {
            box-shadow: 0 0 0 0 transparent;
            transform: translateY(0)
        }
        35% {
            box-shadow: 0 8px 5px -5px #00000040;
            transform: translateY(-35%)
        }
        45% {
            box-shadow: 0 0 0 0 transparent;
            transform: translateY(0)
        }
        55% {
            box-shadow: 0 5px 4px -4px #00000040;
            transform: translateY(-20%)
        }
        70% {
            box-shadow: 0 0 0 0 transparent;
            transform: translateY(0)
        }
        80% {
            box-shadow: 0 4px 3px -3px #00000040;
            transform: translateY(-10%)
        }
        90% {
            box-shadow: 0 0 0 0 transparent;
            transform: translateY(0)
        }
        95% {
            box-shadow: 0 2px 3px -3px #00000040;
            transform: translateY(-2%)
        }
        99% {
            box-shadow: 0 0 0 0 transparent;
            transform: translateY(0)
        }
        to {
            box-shadow: 0 0 0 0 transparent;
            transform: translateY(0)
        }
    }//@keyframes bounce

    /*================================================
05 - section fut
==================================================*/
    .fut {
        background-color: $color1_blue;
        // color: $color_1;
        padding: 23px 0px 17px;
        // font-size: $font_size_1;

        .logo1 {
            img {
                width: 20%;
            }
        }

        .why_stay_covered {
            // max-width: 36rem;
            margin: 0 auto;
            background-color: $color1_blue;
            color: $colorWhite;
            text-align: center;

            h2 {
                font-size: 22px;
            }
        }

        .copyright {
            width: 100%;
            margin: 0px auto 10px;
            text-align: center;
            font-size: $font_size_2;
            font-weight: $font-normal;
            line-height: 1.45em;
            color: $color_1;
        }
    }


    /*================================================
06 - FOOTER
==================================================*/
    footer {
        width: 100%;
        height: auto;
        padding-top: 22px;
        background: $color_1;

        .spine {
            margin: 0 auto;
            padding: 0;
            width: 84.5%;
            height: auto;

            p {
                font-size: $font_size_2;
                float: none;
                text-align: center;
                margin-bottom: 6px;
            }
        }

        p {
            font-size: $font_size_2;
            margin-bottom: 6px;
        }

        ul {
            text-align: center;
            margin: 5px auto;
            padding: 0;

            li {
                margin: 0 3px;
                padding: 0;
                display: inline;
                vertical-align: baseline;
                font-size: $font_size_5;
                color: $color_10;

                a {
                    color: $color_10 !important;
                    text-decoration: none;
                }
            }
        }
    }



    /*================================================
07 - MODAL
==================================================*/

    .modal-content {

        .modal-header {

            h4 {
                font-size: $font_size_8;
            }
        }

        .modal-body {
            p {
                font-size: $font_size_5;
            }

            h3 {
                font-size: $font_size_4;
                font-weight: font-w600;
            }
        }

        .close {
            background: $background_color_1;
            border: none;
            font-size: $font_size_11;
            color: #000;
        }

        table {
            border-collapse: collapse;
        }

        tr {
            border-bottom: 1px solid #000;
        }

        td {
            border-right: 1px solid #000;
            padding: 5px;
        }


    }


    /*================================================
08 - Animation
==================================================*/

    .anim_ylw {
        -webkit-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
        -moz-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
        -ms-animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
        animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
        box-shadow: 0 0 0 0 #007bff8f;
    }

    @-webkit-keyframes pulse {
        to {
            box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
        }
    }

    @-moz-keyframes pulse {
        to {
            box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
        }
    }

    @-ms-keyframes pulse {
        to {
            box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
        }
    }

    @keyframes pulse {
        to {
            box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
        }
    }

    @-webkit-keyframes pulse {
        to {
            box-shadow: 0 0 0 10px rgba(232, 76, 61, 0);
        }
    }

    @-moz-keyframes pulse {
        to {
            box-shadow: 0 0 0 10px rgba(232, 76, 61, 0);
        }
    }

    @-ms-keyframes pulse {
        to {
            box-shadow: 0 0 0 10px rgba(232, 76, 61, 0);
        }
    }

    @-moz-keyframes blink {
        0% {
            opacity: 1;
        }

        50% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    @-webkit-keyframes blink {
        0% {
            opacity: 1;
        }

        50% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    /* IE */
    @-ms-keyframes blink {
        0% {
            opacity: 1;
        }

        50% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    /* Opera and prob css3 final iteration */
    @keyframes blink {
        0% {
            opacity: 1;
            height: 10px;
        }

        50% {
            opacity: 1;
            height: 14px;
        }

        100% {
            opacity: 1;
            height: 18px;
        }
    }


    /*================================================
09 - MEDIA QUERY
==================================================*/

    $break-ipad-pro: 1024px;

    $break-min-ipad: 768px;
    $break-max-ipad: 990px;

    $break-min-tab: 577px;
    $break-max-tab: 767px;

    $break-mob: 576px;

    @media (min-width:$break-max-ipad) and (max-width: $break-ipad-pro) {


        .form_part form .form_cont .form-group {
            .icon_option {
                width: 90% !important;
                margin-right: 5px !important;
            }

        }

    }


    @media (min-width:$break-min-ipad) and (max-width:$break-max-ipad) {

        &::before {
            background-size: cover;
            height: 200px;
        }

        .form_part {
            width: 87% !important;

            h1 {
                font-size: $font_size_10;
            }
        }

        .my-btttnes {
            width: 80% !important;
        }

        // .fut {
        //     img {
        //         padding-bottom: 10px;
        //     }
        // }


    }


    @media (min-width:$break-min-tab) and (max-width:$break-max-tab) {

        .form_part {
            width: 95%;

            h1 {
                text-align: center;
                font-size: $font_size_11 !important;
            }

            .icon_option {
                width: 85% !important;
            }

            .my-bt {
                width: 74% !important;
            }

            .my-btttnes {
                width: 80% !important;
                font-size: $font_size_8 !important;
            }
        }

        .providers {
            display: inline-flex !important;
        }

        // .fut {
        //     img {
        //         width: 35% !important;
        //         padding-bottom: 10px;
        //     }
        // }


    }

    @media screen and (max-width: $break-mob) {

        .start-but {
            width: 100px;
            height: 100px;
            border: 5px solid #e8e8e8;
            position: relative;
            border-radius: 100%;
            display: inline-block;
            color: #8c8c8c;
            font-size: 18px;
            margin-top: -45px;
            margin-bottom: 5px;
            background: #fff;
            padding-top: 30px;
            line-height: 18px;
        }

        &::before {
            position: absolute;
            top: 0px !important;
        }

        .SECT {
            &::before {
                background-color: rgba(0, 0, 0, 0.5);
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 350px;
                z-index: 1;
            }

            h1 {
                font-size: $font_size_9 !important;
                text-align: center;
                margin-bottom: 0px;
                color: $color_1;
                padding: 60px 15px 8px;
                line-height: 40px;
                font-weight: $font-bold;
            }

            h2 {
                font-size: $font_size_16 !important;
                text-align: center;
                margin-bottom: 0px;
                color: $color_1;
                line-height: 30px;
                padding: 15px 15px 50px;
            }

            .pad0 {
                padding: 0px !important;
            }
        }

        header {
            display: none !important;
        }

        .form_part {
            width: 100% !important;
            margin-bottom: 0px;
            border-radius: 0px;
            padding-bottom: 30px;


            form {
                .form_cont {
                    .form-group {
                        margin-bottom: 7px !important;

                        .icon_option {
                            width: 100% !important;
                            margin-top: 0px !important;
                            margin-bottom: 6px !important;
                        }

                        label {
                            margin: 0 !important;
                            padding: 15px 0;
                            font-size: $font_size_8;
                        }
                    }

                    .options {
                        padding: 0px;
                    }

                    .icon_option2 {
                        font-size: $font_size_4 !important;
                    }

                    //                 .dmy{
                    //                    padding: 0px !important;
                    //                }
                    .mobload {
                        margin-bottom: 0px !important;
                    }



                    .form-control {
                        margin-bottom: 6px !important;
                    }

                    .mobpad10 {
                        padding: 0px !important;
                    }

                }

                #slide4,
                #slide5,
                #slide6 {
                    .icon_option1 {
                        margin: 0px 5px 0px 0px !important;
                    }
                }

            }

            #slide1,
            #slide2 {
                .icon_option {
                    font-size: $font_size_8 !important;
                }

                .defaultchk {
                    padding: 0px !important;
                    font-size: $font_size_4 !important;
                }
            }

            #slide3 {
                .custom-checkbox+label {
                    font-size: $font_size_1 !important;
                    padding: 0px 0px 0px 10px !important;
                }

                .form-group {
                    label {
                        padding: 5px 0px !important;
                    }
                }
            }

            .bord {
                padding: 5px 0 10px;

                .my-bt {
                    width: 90%;
                    font-size: $font_size_12;
                    padding: 15px 0px;
                }

                .my-bts {
                    width: 90%;
                    font-size: $font_size_12 !important;
                    padding: 15px 0px;
                }

                .my-btttnes {
                    width: 90%;
                    font-size: $font_size_12 !important;
                    padding: 15px 0px;
                }

                .back_btn {
                    font-size: $font_size_1 !important;
                }
            }

            .consent {
                padding-bottom: 0px !important;

                p {
                    font-size: $font_size_7 !important;
                }
            }

            .authorise {
                p {
                    font-size: $font_size_7 !important;
                }
            }

        }

        // .fut {
        //     padding: 26px 0px 10px;
        //     position: relative;
        //     border-top: $border-style4;

        //     img {
        //         width: 62% !important;
        //         padding-bottom: 12px;
        //     }

        //     .copyright {
        //         font-size: $font_size_7;
        //     }
        // }

        footer {
            img {
                width: 60% !important;
            }

            ul {
                padding: 6px !important;

                li {
                    font-size: $font_size_7 !important;
                }

            }

            p {
                font-size: $font_size_7 !important;
            }
        }
    }

    .show {
        display: block !important;
    }

    .hide {
        display: none !important;
    }

    .error_msg {
        color: #fff;
        width: 100%;
        float: left;
        background: red;
        font-size: 13px;
        padding: 0 5px;
        border-radius: 4px;
        -webkit-border-radius: 4px;
        text-align: center;
        margin-bottom: 5px;
    }

}