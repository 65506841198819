$background_color_1: #ffffff;
$background_color_2: #0070ba;
$background_color_3: #89b0eb;
$background_color_4: rgba(9, 62, 177, 0.68);
$background_color_5: #c32d2d;
$background_color_6: rgba(21, 54, 111, 0.45);

$color_1: #ffffff;
$color_2: #000000;
$color_3: rgba(9, 62, 177, 0.68);
$color_4: #0070ba;

$font_family_1: Titillium Web, sans-serif;


.modal {
  border: 1px solid black;
  // .hide{
  //   display: none;
  // }
  // .show{
  //   display: block;
  // }

  input{
    display: none;
  }
  .modal-body{
    font-size: 20px;
    padding: 35px 10px;
    font-weight: 600;


  }
  .btn_reClaim{
    width: 85%;
    background: #00a700;
    color: #fff;
    font-weight: bold;
    border: 0;

    &:hover{
      background: #007200;
    }
  }
  label.radioa {
    background: rgba(236,236,236,0.54) url(../img/unchecked.png) no-repeat 6px;
    border: 2px solid $color_3;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    height: 48px;
    position: relative;
    cursor: pointer;
    outline: none;
    margin: 0;
    padding: 0px 0px 0px 50px;
    line-height: 44px;
    margin-bottom: 10px;
    width: 100%;
    float: left;
    text-align: left;
    clear: none;
    color: $color_3;
    font-weight: normal;
    display: block;
    font-size: 15px;

    span {
      position: absolute;
      left: 2px;
      color: #fff;
      font-weight: 400;
      height: 40px;
      line-height: 40px;
      padding: 0px 14px;
      margin: 2px 0px;
      background-color: $background_color_2;
      border-radius: 5px;
    }
  }

  input:checked+label {
    color: #fff;
    background: rgba(9,131,168,0.68) url(../img/checked.png) no-repeat 6px !important;
    color: #fff !important;
    border: 2px solid rgba(9,62,177,0.68);
}
  input.radio {
    position: absolute;
    visibility: hidden;
  }

  .radio {
    &:checked + label {
      background-color: #0983a8ad !important;
      color: #fff;
    }
  }
}




@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes btn_anime_glowing {
  0% {
    transform: scale(.9);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 30px rgba(#5ad46e, 0);
  }
    100% {
      transform: scale(.9);
    box-shadow: 0 0 0 0 rgba(#5ad46e, 0);
  }
}
.btn-anime{
  box-shadow: 0 0 0 0 rgba(#5a99d4, .5);
  -webkit-animation: btn_anime_glowing 1.5s infinite;
  animation: btn_anime_glowing 1.5s cubic-bezier(0.57, 0.31, 0.36, 1) infinite;

}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

.animated {
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0);
    -webkit-transition: -webkit-transform 2.6s ease-in-out;
    transition: transform 0.6s ease-in-out;
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}

@-webkit-keyframes fadeInUp {
  from {
    transform: translate3d(0, 40px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
.fadeInUp {
  opacity: 0;
  animation-name: fadeInUp;
  -webkit-animation-name: fadeInUp;
}
